<template>
  <div class="tc-user-subscribe">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="用户编号" prop="scaleName">
            <el-input v-model="formInline.scaleName"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="formInline.userName"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="department">
            <el-select v-model="formInline.department">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="出生范围">
            <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item> -->
          <el-form-item label="问诊医生" prop="userName">
            <el-input v-model="formInline.userName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="onSubmit()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="account" label="用户编号" width="150" align="center"></el-table-column>
        <el-table-column prop="real_name" label="姓名" width="150" align="center"></el-table-column>
        <el-table-column prop="department_name" label="性别" width="100" align="center"></el-table-column>
        <el-table-column prop="department_name" label="年龄" width="100" align="center"></el-table-column>
        <el-table-column prop="department_name" label="预约时间" width="150" align="center"></el-table-column>
        <el-table-column prop="department_name" label="预约医生" width="150" align="center"></el-table-column>
        <el-table-column prop="department_name" label="预约方向" width="150" align="center"></el-table-column>
        <el-table-column prop="department_name" label="状态" width="100" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template>
            <el-button size="mini" type="plain">语音</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain">视频</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain">查看信息</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        report_tale: "",
        department: "",
        userName: "",
        scaleName: "",
        date: []
      },
      tableData: []
    };
  },
  methods: {
    handleSelectionChange() {}
  }
};
</script>
<style lang="less">
.tc-user-subscribe {
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(4) .el-input__inner {
        width: 200px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
}
</style>